export const MESSAGE_DATA = [
	{
		image: "15",
		user: "David Belle",
		content: "Cum sociis natoque penatibus et magnis dis parturient montes",
		time: "10:02 AM",
		status: "unread",
	},
	{
		cap: "AW",
		color: "bg-red-400",
		user: "Aaron Warner",
		content: "Nunc quis diam diamurabitur at dolor elementum, dictum turpis vel",
		time: "02:30 PM",
		status: "unread",
	},
	{
		cap: "FM",
		color: "bg-blue-400",
		user: "Fredric Mitchell Jr.",
		content: "Phasellus a ante et est ornare accumsan at vel magnauis blandit turpis at augue ultricies",
		time: "Tue",
		status: "unread",
	},
	{
		image: "12",
		user: "Glenn Jecobs",
		content: "Ut vitae lacus sem ellentesque maximus, nunc sit amet varius dignissim, dui est consectetur neque",
		time: "Mon",
		status: "unread",
	},
	{
		image: "11",
		user: "Bill Phillips",
		content: "Proin laoreet commodo eros id faucibus. Donec ligula quam, imperdiet vel ante placerat",
		time: "Fri",
		status: "unread",
	},
	{
		image: "4",
		user: "Alice Margit",
		content: "Aenean lacinia bibendum nulla sed consectetur",
		time: "May 10",
		status: "read",
	},
	{
		cap: "VH",
		color: "bg-amber-400",
		user: "Van Hasnon",
		content: "Nullam iolor ieonibh ultricies vehicula magna",
		time: "May 09",
		status: "unread",
	},
	{
		cap: "IA",
		color: "bg-purple-400",
		user: "Iason Antonius",
		content: "Maecenas faucibus mollis interdum Maecenas sed diam eget risus varius blandit sit amet non magna",
		time: "May 08",
		status: "read",
	},
	{
		image: "5",
		user: "Alma Graves",
		content: "Nisi ad aliqua do ipsum incididunt proident",
		time: "May 08",
		status: "read",
	},
	{
		image: "6",
		user: "Jackson Lee",
		content: "Ut ut enim et exercitation aute duis sint reprehenderit et nostrud",
		time: "May 08",
		status: "read",
	},
	{
		image: "7",
		user: "Susie Snyder",
		content: "Tempor nulla sint sint tempor",
		time: "May 11",
		status: "read",
	},
	{
		image: "8",
		user: "Anne Sutton",
		content: "Esse exercitation veniam mollit dolor officia aute cupidatat laboris",
		time: "May 12",
		status: "read",
	},
	{
		cap: "RH",
		color: "bg-pink-400",
		user: "Rachel Henderson",
		content: "Commodo eiusmod anim adipisicing anim et laboris incididunt deserunt enim deserunt sint",
		time: "May 12",
		status: "read",
	},
	{
		image: "9",
		user: "Barry Ray",
		content: "Ipsum cupidatat incididunt minim dolore occaecat quis non sit elit commodo deserunt minim",
		time: "May 13",
		status: "read",
	},
	{
		cap: "FT",
		color: "bg-green-400",
		user: "Frank Townsend",
		content: "Reprehenderit qui qui excepteur irure Lorem adipisicing et ad id ea commodo sit ut",
		time: "May 14",
		status: "read",
	},
];

export const DIRECT_MESSAGE_DATA = [
	{
		id: 6,
		name: "Candace Munoz",
		img: "1",
		time: "2 minutes",
		lastMessage: "Nunc posuere nunc nec felis malesuada vestibulum",
		online: true,
		unread: true,
	},
	{
		id: 7,
		name: "Antonia Griffith",
		cap: "A",
		color: "bg-blue-400",
		time: "5 minutes",
		lastMessage: "Aliquam volutpat anteac suscipit sodales",
		online: true,
		unread: true,
	},
	{
		id: 8,
		name: "Gloria Singleton",
		img: "2",
		time: "10 minutes",
		lastMessage: "Maecenas efficitur ligulaid lectus scelerisque pretium",
		online: true,
		unread: true,
	},
	{
		id: 9,
		name: "Whitney Stewart",
		img: "3",
		time: "55 minutes",
		lastMessage: "Mauris quis leo id dui accumsan tempus",
		online: true,
	},
	{
		id: 10,
		name: "Maria Zimmerman",
		img: "4",
		time: "2 hours",
		lastMessage: "Proin efficitur euismod magna a facilisis",
		online: true,
	},
	{
		id: 11,
		name: "Samantha French",
		cap: "S",
		color: "bg-teal-400",
		time: "5 hours",
		lastMessage: "Vivamus mollis euismod nunc",
		online: false,
	},
	{
		id: 12,
		name: "Wendy Mclaughlin",
		cap: "B",
		color: "bg-purple-400",
		time: "10 hours",
		lastMessage: "Cras lacus arcu tincidunt quis commodo vel",
		online: false,
		unread: true,
	},
	{
		id: 13,
		name: "Kristin Turner",
		img: "9",
		time: "12 hours",
		lastMessage: "Curabitur tincidunt",
		online: false,
	},
	{
		id: 14,
		name: "Dewey Marsh",
		cap: "D",
		color: "bg-red-400",
		time: "15 hours",
		lastMessage: "Vestibulum lobortis risusid erat egestas",
		online: false,
		unread: true,
	},
	{
		id: 15,
		name: "Hector Lawson",
		img: "6",
		time: "20 hours",
		lastMessage: "Aliquam",
		online: false,
	},
	{
		id: 16,
		name: "Sherman Perkins",
		cap: "S",
		color: "bg-amber-400",
		time: "Yesterday",
		lastMessage: "Proin in posuere arcu",
		online: false,
	},
	{
		id: 17,
		name: "Christopher Lawrence",
		img: "7",
		time: "2 days",
		lastMessage: "Curabitur tincidunt",
		online: false,
	},
	{
		id: 18,
		name: "Malcolm Cannon",
		img: "8",
		time: "2 days",
		lastMessage: "Duis cursus lacus eros",
		online: false,
	},
	{
		id: 19,
		name: "Lance Bishop",
		img: "11",
		time: "3 days",
		lastMessage: "Praesent imperdiet rhoncus fringilla",
		online: false,
	},
	{
		id: 20,
		name: "Harold Wilkerson",
		img: "12",
		time: "4 days",
		lastMessage: "Phasellus magna turpis",
		online: false,
	},
	{
		id: 21,
		name: "Rudy Hamilton",
		img: "15",
		time: "4 days",
		lastMessage: "Maecenas nisl leo",
		online: false,
	},
	{
		id: 22,
		name: "Mabel Copeland",
		img: "14",
		time: "5 days",
		lastMessage: "Praesent sit amet velit",
		online: false,
	},
	{
		id: 23,
		name: "Sarah Hammond",
		cap: "S",
		color: "bg-green-400",
		time: "a week",
		lastMessage: "Etiam vel ullamcorper tellus",
		online: false,
	},
	{
		id: 24,
		name: "Gladys Matthews",
		img: "13",
		time: "2 months",
		lastMessage: "Fusce eget nunc et sapien posuere ornare",
		online: false,
	},
];

export const CONVERSATION_DATA = [
	{
		id: 1,
		day: "November 18, 2024",
		chat: [
			{
				id: 1.1,
				time: "8:32 PM",
				op: {
					name: "Constance Mendoza",
					img: "1",
				},
				text: [
					{
						id: 1.11,
						text: "Hard together found of emerged",
					},
					{
						id: 1.12,
						text: "Delicacy of must but the is acknowledge in failures on ahead with phase project the several arrives government absolutely know out place and repeat his crap copy. Projects feedback dull was go I king's war, not lie spirits my they and gradually road.",
					},
					{
						id: 1.13,
						text: "Nunc luctus ultrices lacus",
					},
				],
			},
			{
				id: 1.2,
				time: "8:50 PM",
				op: {
					name: "Deborah Harrison",
					img: "2",
				},
				text: [
					{
						id: 1.21,
						text: "Pharetra sit amet aliquam id diam maecenas ultricies. " + "Fusce ut placerat orci nulla pellentesque dignissim enim sit amet",
					},
					{
						id: 1.22,
						text: "Ut lectus nunc, suscipit quis nulla ac, tempus convallis ipsum. " + "Morbi lobortis elit sit amet rutrum finibus. Morbi id blandit turpis. " + "Fusce sed ex vel odio tristique rhoncus tempus ac nisi. Fusce vitae orci ac felis lobortis tempor. " + "Vivamus orci turpis, laoreet vel suscipit quis, pulvinar non nunc. Donec semper laoreet dictum.",
					},
				],
			},
			{
				id: 1.3,
				time: "9:00 PM",
				op: {
					name: "Marsha Maxwell",
					cap: "M",
					color: "bg-blue-400",
				},
				text: [
					{
						id: 1.31,
						text: "Faucibus ornare suspendisse sed nisi lacus sed viverra tellus in. " + "Mi quis hendrerit dolor magna eget est lorem ipsum dolor. Amet nulla facilisi morbi tempus. " + "Posuere morbi leo urna molestie at elementum eu facilisis sed",
					},
					{
						id: 1.32,
						text: "Nam ac molestie leo",
					},
				],
			},
			{
				id: 1.4,
				time: "9:18 PM",
				op: {
					name: "Constance Mendoza",
					img: "1",
				},
				text: [
					{
						id: 1.41,
						text: "Gravida quis blandit turpis cursus. Tincidunt dui ut ornare lectus sit. " + "Cras ornare arcu dui vivamus arcu felis bibendum ut tristique. " + "Viverra orci sagittis eu volutpat odio facilisis mauris sit. Ut faucibus pulvinar elementum integer",
					},
				],
			},
			{
				id: 1.5,
				time: "9:34 PM",
				op: {
					name: "Marlene Elliott",
					img: "3",
				},
				text: [
					{
						id: 1.51,
						text: "Viverra maecenas accumsan lacus vel facilisis",
					},
				],
			},
			{
				id: 1.6,
				time: "10:02 PM",
				op: {
					name: "Rochelle Castillo",
					cap: "R",
					color: "bg-red-400",
				},
				text: [
					{
						id: "1.61",
						text: "Vitae tempus quam pellentesque nec nam aliquam sem et tortor. Mauris in aliquam sem fringilla",
					},
				],
			},
			{
				id: 1.7,
				time: "11:43 PM",
				op: {
					name: "Marsha Maxwell",
					cap: "M",
					color: "bg-blue-400",
				},
				text: [
					{
						id: 1.71,
						text: "Tortor vitae purus",
					},
				],
			},
		],
	},
	{
		id: 2,
		day: "November 19, 2024",
		chat: [
			{
				id: 2.1,
				time: "9:12 AM",
				op: {
					name: "Salvador Burton",
					img: "15",
				},
				text: [
					{
						id: 2.11,
						text: "Gravida quis blandit turpis cursus",
					},
				],
			},
			{
				id: 2.2,
				time: "10:00 AM",
				op: {
					name: "Oliver Ramirez",
					cap: "O",
					color: "bg-orange-400",
				},
				text: [
					{
						id: 2.21,
						text: "Tortor vitae purus faucibus ornare suspendisse sed nisi lacus. " + "Tellus mauris a diam maecenas sed enim ut sem viverra. Consequat mauris nunc congue nisi vitae",
					},
					{
						id: 2.22,
						text: "Nam ac molestie leo",
					},
					{
						id: 2.23,
						text: "Yeah",
					},
				],
			},
			{
				id: 2.3,
				time: "11:24 AM",
				op: {
					name: "Salvador Burton",
					img: "15",
				},
				text: [
					{
						id: 2.31,
						text: "Ipsum faucibus vitae aliquet nec ullamcorper sit",
					},
				],
			},
		],
	},
];

export const CHANNEL_MEMBERS = [
	{
		id: 6,
		name: "Candace Munoz",
		img: "1",
		display: "candace",
		online: true,
	},
	{
		id: 7,
		name: "Antonia Griffith",
		cap: "A",
		color: "bg-blue-400",
		display: "antonia-ls",
		online: true,
	},
	{
		id: 8,
		name: "Gloria Singleton",
		img: "2",
		display: "singleton2021",
		online: true,
	},
	{
		id: 9,
		name: "Whitney Stewart",
		img: "3",
		display: "stewart",
		online: true,
	},
	{
		id: 10,
		name: "Maria Zimmerman",
		img: "4",
		display: "zimmerman",
		online: true,
	},
	{
		id: 11,
		name: "Samantha French",
		cap: "S",
		color: "bg-teal-400",
		display: "samanthafrench",
		online: false,
	},
	{
		id: 12,
		name: "Wendy Mclaughlin",
		cap: "B",
		color: "bg-purple-400",
		display: "wendy",
		online: false,
	},
	{
		id: 13,
		name: "Kristin Turner",
		img: "9",
		display: "wenurner",
		online: false,
	},
	{
		id: 14,
		name: "Dewey Marsh",
		cap: "D",
		color: "bg-red-400",
		display: "marsh",
		online: false,
	},
	{
		id: 15,
		name: "Hector Lawson",
		img: "6",
		display: "lawson-h",
		online: false,
	},
	{
		id: 16,
		name: "Sherman Perkins",
		cap: "S",
		color: "bg-amber-400",
		display: "sherman",
		online: false,
	},
];

export const CHANNEL_FILES = [
	{
		type: "code",
		icon: "code",
		name: "package.json",
		size: "229 bytes",
		owner: "Isabelle Reid",
		date: "01/06",
	},
	{
		type: "doc",
		icon: "article",
		name: "cover_letter.doc",
		size: "32 kb",
		owner: "Derrick Porter",
		date: "05/06",
	},
	{
		type: "audio",
		icon: "headset",
		name: "recording_09-10.mp3",
		size: "2.32 mb",
		owner: "Catherine Chandler",
		date: "02/07",
	},
	{
		type: "image",
		img: "2",
		name: "wallpaper-widescreen.jpg",
		size: "900 kb",
		owner: "Elva Erickson",
		date: "28/10",
	},
	{
		type: "image",
		img: "1",
		name: "screenshot-98731.jpg",
		size: "653 kb",
		owner: "Kathryn Williamson",
		date: "26/08",
	},
	{
		type: "video",
		icon: "play_circle",
		name: "animation-trailer.mp4",
		size: "43.86 mb",
		owner: "Lottie Conner",
		date: "08/10",
	},
	{
		type: "pdf",
		icon: "picture_as_pdf",
		name: "instructions.pdf",
		size: "205.5 kb",
		owner: "Frederick Alvarez",
		date: "23/05",
	},
	{
		type: "spreadsheet",
		icon: "table_chart",
		name: "signup-list.xls",
		size: "987.23 kb",
		owner: "Jerry McKenzie",
		date: "02/05",
	},
	{
		id: 15,
		type: "image",
		img: "13",
		name: "sample-preview.jpg",
		size: "2.34 mb",
		owner: "Betty Berry",
		date: "14/11",
	},
	{
		type: "text",
		icon: "subject",
		name: "detailed-notes.txt",
		size: "54.5 kb",
		owner: "Della Reyes",
		date: "31/07",
	},
	{
		type: "presentation",
		icon: "scatter_plot",
		name: "presentation.ppt",
		size: "10.12 mb",
		owner: "Callie Kelley",
		date: "09/10",
	},
];

import jsVectorMap from "jsvectormap";
import "../../vendors/jsvectormap/maps/us-aea-en";
import { resizeMap } from "../../vendors/jsVectorMap";

export const salesByRegion = () => {
	//---------------------------------------------------------
	// Map
	//---------------------------------------------------------
	const MAP_WRAPPER = document.getElementById("map-sales-region");

	if (MAP_WRAPPER) {
		// Resize map on load
		resizeMap(MAP_WRAPPER);

		// Initiate map
		new jsVectorMap({
			selector: "#map-sales-region",
			map: "us_aea_en",
			draggable: false,
			zoomButtons: false,
			zoomOnScroll: false,
			regionStyle: {
				initial: {
					fill: "INITIAL_FILL",
					"fill-opacity": 1,
					stroke: "none",
				},
			},
			visualizeData: {
				scale: ["ACTIVE_FILL", "ACTIVE_FILL"],
				values: {
					"US-NY": 1000,
					"US-TX": 900,
					"US-CA": 800,
					"US-FL": 700,
					"US-ID": 600,
					"US-NE": 200,
				},
			},
			onLoaded(map) {
				window.addEventListener("resize", () => {
					// Set map size on window resize
					resizeMap(MAP_WRAPPER);

					setTimeout(() => {
						map.updateSize();
					});
				});
			},
		});
	}

	//---------------------------------------------------------
	// Data
	//---------------------------------------------------------
	const WRAPPER = document.getElementById("list-sales-region");
	const REGION_ICONS = {
		NY: `<svg height="21" width="25">
				<path d="M23.404 18.293c-.466-.113-.129-.7-.404-.293-.087.173.215-.85 0-1-.295-.206-1.102.364-1 0 .24-.63-.592-.95-.62-1.622-.288-1.483-.742-2.967-.511-4.495-.016-.896.311-1.803-.103-2.655-.245-.515-.094-1.373-.814-1.476-.6-.133.15-.866-.315-1.246-.342-.489-.638-.987-.31-1.57.076-1.16-.77-2.125-.714-3.287l-1.4.39-.007-.004c-.543.225-1.096.337-1.675.287-1.019.145-2.044.549-2.597 1.47-.57.69-.983 1.505-1.443 2.251-.332.475-1.17.75-1.116 1.375-.034.716 1.16.147.89.88-.54.463.161.851.113 1.326.1.697-.856.4-1.077.804-.326.545-.674 1.33-1.452 1.216-.61-.086-1.075.412-1.604.529-.503-.151-.945-.412-1.526-.264-1.067.043-2.135.398-3.062.911-.058.356.123 1.256.638 1.234.311.499.42.997-.127 1.351-.303.354-.362.855-.836 1.066-.388.229-.539.705-.965.912.066.618-.044 1.466.82 1.03l13.314-2.762c.197.774 1.14.265 1.295.804.129.589.273 1.487 1.071 1.383.632.382 1.388.74 2.15.848.544.095 1.443.288 1.294 1.017-.066.396-.326 1.52.396 1.192 1.066-.331 1.469-.089 2.293-.895-.585.314.037-.87-.596-.707z" fill="none" stroke="currentColor" stroke-linejoin="round"/>
			</svg>`,
		TX: `<svg height="23" width="23">
				<path d="M6.926 3.127l-.417 5.568-.134 1.155-4.755-.346-1.111-.099c-.06.333.202.328.36.545.2.163.1.537.413.578s.259.41.486.565c.3.28.49.704.914.806.17.113.356.287.403.486.061.353.403.625.309 1.009-.079.458.197.857.528 1.146.194.263.457.424.768.515.167.173.268.345.509.434.23.016.479.386.654.105.23-.28.488-.57.539-.939.112-.251.318-.385.578-.45.242-.142.474-.19.665.055.437.05.908.047 1.317.197.252.128.228.404.46.564.154.182.43.3.517.518.122.184.237.38.24.626.144.386.37.757.472 1.151-.021.247.414.222.44.49.2.364.39.817.82.935.292.178.003.448.081.667.292.078 0 .417.084.594.225.121.39.285.376.573.035.297.19.608.506.671.268.172.596.19.879.303.203.16.458.364.726.252.308.04.603.115.834.334.127.226.58-.081.384-.258-.182-.301-.116-.693-.252-1.02-.056-.273-.213-.53-.088-.81l.36-1.309a.217.217 0 0 1 .107-.42c.355.038.325-.572.695-.538.468-.139.808-.534 1.26-.716.616-.25 1.213-.575 1.667-1.073.23-.265.631-.352.774-.69.445-.197.872-.439 1.354-.534-.086-.235.047-.432.118-.642.034-.267.017-.54.105-.796-.28-.202.034-.437.123-.66-.018-.25.126-.556.008-.77.026-.261-.133-.458-.299-.65-.219-.235-.098-.615-.344-.847-.225-.407-.142-.907-.2-1.355.001-.445.017-.89-.045-1.332-.234-.206-.491.207-.67-.122-.275-.184-.681-.187-.865-.506-.206-.22-.34.253-.64.086-.17-.243-.319.002-.532.016-.202-.103-.54-.132-.542.156-.245.211-.53-.082-.795-.114-.268.123-.466-.34-.561-.166-.014.237-.225.454-.457.297-.287-.013-.437-.221-.585-.346-.262-.155-.382.207-.617.078-.132-.123-.167-.32-.35-.503-.272-.251-.447.283-.634.02-.182-.187-.496-.073-.707-.239-.317.053-.493-.02-.367-.365-.169-.165-.203.107-.425-.013-.052-.036-.307.159-.445-.099-.17-.169-.457-.286-.372-.574.003-.941.022-1.882.148-2.817l.079-.8a260.212 260.212 0 0 1-4.712-.197l-.14 1.622v-.002z" fill="none" stroke="currentColor" />
			</svg>`,
		CA: `<svg height="26" width="18">
				<path d="M2.99 1.357c-.012.404.045.82-.228 1.174-.213.368-.291.824-.74 1.037-.137.211-.4.338-.411.645-.222.348.284.564.332.897.176.339.268.693.187 1.064 0 .292-.32.5-.256.813.005.297-.257.587.004.853.295.5.73.992.767 1.568-.061.277-.113.536.207.716.183.195.514.366.32.646-.198.386-.13.803-.125 1.215.192.266.324.675.762.652.169.233.11.483-.026.712-.286.153-.498.273-.418.608.03.348.488.533.498.9.167.62.473 1.19.868 1.723.082.257.247.434.332.64-.027.333-.22.65-.275.987-.19.26.136.551.456.511.46.015.831.331 1.259.404.343-.055.542.29.694.51.176.271.271.6.659.688.287.114.708.005.82.345.275.271-.273.504.161.516.312.187.636-.174.865-.074.243.206.463.42.563.704.492.49.165 1.176.32 1.75l5.062.538c.318.12.708-.442.325-.464-.358.064-.323-.402-.155-.466.36-.088.562-.382.531-.703.054-.398.374-.742.826-.84.392-.203-.038-.357-.09-.578-.027-.364-.223-.68-.414-.988.23-.366-.254-.332-.362-.623L8.553 9.217l2.152-6.725L3.614.717l-.624.64z" fill="none" stroke="currentColor" />
			</svg>`,
		FL: `<svg height="23" width="28">
				<path d="M18.24 1.168c.268.522.237 1.094.183 1.669-.654.097-.341-.838-.883-.713-.981-.012-1.96.202-2.945.213-1.603.052-3.235.382-4.818.114-.408-.28-.45-1.099-1.032-.952-1.449-.022-2.888.32-4.33.455l-2.769.38c-.225.58.413.78.645 1.132.127.408-.248 1.505.348 1.269.653-.215 1.283-.524 1.982-.486a2.374 2.374 0 0 1 1.571.08c.65.143 1.235.552 1.813.89.28.347.873.334.948.894-.022.584.686-.168 1.033.094.506-.143.832-.657 1.221-.983.772.302.099-.518.52-.709.497-.148 1.051-.248 1.485.141.483.102.862.358 1.043.892.585.004.458.738.87.947.47.088.474.808 1.001.768.463.065.866.206.928.796.326.377.623.761.491 1.324.029.657.019 1.31-.229 1.92.062.659.218 1.42.521 1.927.358-.618.027-.691-.276-1.077.348-.315.772-.04 1.16.028.13.563-.344 1.001-.553 1.464-.525.395.262.73.433 1.126.494.597.691 1.419 1.196 2.012.124.41.4 1.335.736.552.403-.043.616.615.839.967-.004.404.306 1.258.57 1.15.457-.142.96.117 1.315.464.407.59.728 1.247.724 2.013.218.488.723.08.923-.203.594.08 1.154-.224 1.465-.799-.16-.422-.09-.863-.051-1.281-.006-.39.688-.57.357-1.164-.155-1.13-.03-2.316-.297-3.44-.39-1.238-1.197-2.276-1.652-3.495-.24-.43-.673-.7-.733-1.258-.15-.408-.425-.885-.012-1.2-.062-.635-.772-.968-1.086-1.502-.855-.995-1.317-2.312-1.962-3.474l-.939-2.999c-.544.013-1.16-.184-1.661-.062l-.054.066-.041.052.001-.002z" fill="none" stroke="currentColor" />
			</svg>`,
		ID: `<svg height="26" width="19">
				<path d="M5.906 11.02c.062.43-.094.776-.467 1.04-.354.383-.674.848-.937 1.254.06.417-.396.504-.652.683-.272.328-.659.61-.605 1.066-.098.345.722.08.63.617-.8 1.587-1.044 3.341-1.56 5.02l-.31 1.148c8.765 1.825 9.587 1.912 14.41 2.759l1.27-7.506c-.41-.12-.09-.927-.651-.707-.191.242-.25.704-.796.494-.479-.283-1.049-.19-1.56-.08-.39-.25-.91-.286-1.184.125-.27-.007-.507-.482-.43-.762.294-.565-.438-.837-.777-1.175-.151-.836-.536-1.655-.385-2.514-.287-.002-.655.382-.996.515-.34.026-.696-.439-.632-.754.184-.764.627-1.474.906-2.218.3-.375.172-.791-.371-.798-.24-.48-.758-.805-.864-1.355-.202-.374-.219-.92-.78-.96-.152-.264-.489-.636-.293-.957.321-.424-.053-.81-.236-1.208-.328-.434.085-.95.103-1.423l.414-1.854-1.929-.332-2.079 8.919c.08.436.35.746.76.963z" fill="none" stroke="currentColor" />
			</svg>`,
	};
	const DATA = [
		{
			region: "New York",
			icon: REGION_ICONS.NY,
			sales: 24857.66,
			percentage: 32.4,
			up: true,
		},
		{
			region: "Texas",
			icon: REGION_ICONS.TX,
			sales: 22543.08,
			percentage: 67.8,
			up: false,
		},
		{
			region: "California",
			icon: REGION_ICONS.CA,
			sales: 20784.76,
			percentage: 95.1,
			up: true,
		},
		{
			region: "Florida",
			icon: REGION_ICONS.FL,
			sales: 19564.76,
			percentage: 12.4,
			up: false,
		},
		{
			region: "Idaho",
			icon: REGION_ICONS.ID,
			sales: 15003.24,
			percentage: 43.5,
			up: false,
		},
	];

	if (WRAPPER) {
		let list = "";
		DATA.forEach((item, i) => {
			let border = i !== DATA.length - 1 && "border-b";
			list += `<div class="flex items-center border-light-200 dark:border-dark-200 py-3 text-heading ${border}">
                        <div class="w-10 text-green-500">${item.icon}</div>
						<div class="flex-1 text-truncate">${item.region}</div>
						<div class="font-bold ml-10">${item.sales}</div>
						<div class="flex w-16 items-center justify-end ${item.up ? "text-green-500" : "text-red-500"}">
                            ${item.percentage}
							<span class="icon font-light ml-1">arrow_circle_${item.up ? "up" : "down"}</span>
						</div>
					</div>`;
		});
		WRAPPER.innerHTML = list;
	}
};

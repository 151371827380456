const _temp0 = require("../../../../img/contacts/1.jpg");
const _temp1 = require("../../../../img/contacts/2.jpg");
const _temp2 = require("../../../../img/contacts/3.jpg");
const _temp3 = require("../../../../img/contacts/4.jpg");
const _temp4 = require("../../../../img/contacts/5.jpg");
const _temp5 = require("../../../../img/contacts/6.jpg");
const _temp6 = require("../../../../img/contacts/7.jpg");
const _temp7 = require("../../../../img/contacts/8.jpg");
const _temp8 = require("../../../../img/contacts/9.jpg");
const _temp9 = require("../../../../img/contacts/10.jpg");
const _temp10 = require("../../../../img/contacts/11.jpg");
const _temp11 = require("../../../../img/contacts/12.jpg");
const _temp12 = require("../../../../img/contacts/13.jpg");
const _temp13 = require("../../../../img/contacts/14.jpg");
const _temp14 = require("../../../../img/contacts/15.jpg");
const _temp15 = require("../../../../img/contacts/16.jpg");
module.exports = {
  "1": _temp0,
  "2": _temp1,
  "3": _temp2,
  "4": _temp3,
  "5": _temp4,
  "6": _temp5,
  "7": _temp6,
  "8": _temp7,
  "9": _temp8,
  "10": _temp9,
  "11": _temp10,
  "12": _temp11,
  "13": _temp12,
  "14": _temp13,
  "15": _temp14,
  "16": _temp15
}